class GravityForms
{
    constructor()
    {
        this.addCloseButtonHandler();
    }

    addCloseButtonHandler()
    {
        let closeButtons = document.querySelectorAll('.validation_error button');
        
        closeButtons.forEach(btn => {
            btn.addEventListener('click' , (e) => {
                e.preventDefault();

                let message = btn.parentElement;
                message.classList.add('-hide');
            });
        });
    }
}

export default GravityForms;