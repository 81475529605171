import SubMenu from "./sub-menu";
import eventPath from "./event-path";
import MobileMenu from "./mobile-menu";
import SearchOverlay from "./search-overlay";
import {isOnDesktop} from './utils';

export default class Menu {
    constructor(id) {
        this.menu = document.querySelector(id);
        /* For each sub-menu in the menu, create a new instance of SubMenu */
        this.subMenus = [...this.menu.querySelectorAll(".sub-menu-wrapper")].map(
            e => new SubMenu(e)
        );
        this.mobileMenu = new MobileMenu();
        this.searchOverlay = new SearchOverlay();
        this.init();
    }
    init() {
        /* Escape key closes all menus */
        this.handleEscKey();
        /* Clicking of the menu closes all menus */
        this.handleBlur();
        /** Clicking the overlay closes all menus */
        this.handleOverlayClick();
        /** Tabbing out of the menu closes the menu */
        document.addEventListener('focus', () => this.closeOnFocusLoss(), true);
    }

    closeOnFocusLoss() {
        let focusInsideMenu = this.mobileMenu.target.contains(document.activeElement);
        let focusInsideSearchOverlay = this.searchOverlay.overlay.contains(document.activeElement);

        if(focusInsideMenu){
            let isSearchCloseButton = document.activeElement.classList.contains('search-close');
            if(this.searchOverlay.isOpen() && !focusInsideSearchOverlay && !isSearchCloseButton){
                this.searchOverlay.close();
            }

            return;
        }

        if(isOnDesktop()){
            if(this.searchOverlay.isOpen()){
                this.searchOverlay.close();
            }

            return;
        }

        // On Mobile
        if(!this.mobileMenu.isOpen()){
            return;
        }

        if(this.searchOverlay.isOpen()){
            this.searchOverlay.close();
        } else {
            this.mobileMenu.close();
        }
    }
    handleOverlayClick() {
        document.querySelector('.gradient-overlay').addEventListener('click', () => {
            if(this.mobileMenu.isOpen()){
                this.mobileMenu.close();
                this.closeAllSubmenus();
            }

            this.searchOverlay.close();
        });
    }
    handleEscKey() {
        this.menu.onkeyup = (e) => {
            if (e.key === "Escape" || e.key === "Esc") {
                let isSubMenuOpen = this.subMenus.some(submenu => submenu.isOpen());
                if(isSubMenuOpen){
                    let openMenu = document.querySelector(
                        'a[aria-expanded="true"]'
                    );
    
                    if (openMenu) {
                        openMenu.focus(); // Focus on top level menu item
                    }
                    this.closeAllSubmenus();

                    return;
                }

                if(this.searchOverlay.isOpen()){
                    this.searchOverlay.close();

                    return;
                }

                this.mobileMenu.close();
            }
        };
    }
    handleBlur() {
        document.documentElement.addEventListener("click", (e) => {
            if (!eventPath(e).includes(this.menu)) {
                this.closeAllSubmenus();
            }
        });
    }
    closeAllSubmenus() {
        for (let ul of this.subMenus) {
            ul.close();
        }
    }
}
